export function useIsMemberPage() {
  const pageStore = usePageStore();
  const staffStore = useStaffStore();

  const isMembersPage = computed(() => {
    if (pageStore.currentOrganization) {
      return pageStore.currentOrganization.donorsType === "members";
    } else if (staffStore.info) {
      return staffStore.info.organization.donorsType === "members";
    }
    return true;
  });

  return isMembersPage;
}